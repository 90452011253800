import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "../components/layout"
import { container, column, text } from "./about.module.css"

const about = () => {
  return (
    <Layout>
      <div className={container}>
        <div className={text}>
          <p>
            In 2010, life and business partners, Leti and Ken, hired a Cal Tech
            Engineer specialized in LED technology, to develop an LED red light
            medical device for pain relief.
          </p>
          <p>
            Leti, an industrial designer, and successful e-commerce specialist
            had recently lost a young cousin to a heart attack, brought on by
            years of high dose prescription drug therapy used to treat severe
            juvenile rheumatoid arthritis. She wanted to brand and market this
            drug-free device to as many pain sufferers as possible.
          </p>
          <p>
            Ken, a pain sufferer himself, had worked in the pharmaceutical
            industry for years and was very impressed with this evidence-based
            technology that could treat pain without the usual side effects of
            medication. Together they knew that with her expertise in product
            design and e-commerce, and Ken’s healthcare knowledge, they could
            build a brand and an internet-based distribution system that would
            bring these great products directly to pain sufferers everywhere. It
            was the beginning of Lumina Group.
          </p>
          <p>
            Scientific evidence showed the latest high power LEDs were just as
            effective for pain management as some expensive laser machines, only
            safer!
          </p>
          <p>
            Once the first product, the Tendlite, was FDA cleared in October
            2010, the couple needed to see for themselves how good the product
            really was before branding it and bringing it to market. They
            brought it to doctors, trainers, athletes, and everyday pain
            sufferers to see if this device was able to produce the same results
            as science researchers were finding in their labs. Fortunately, the
            feedback was tremendous. People really saw incredible improvement in
            pain, and they saw it fast, without drug side effects!
          </p>
        </div>
        <StaticImage
          src="../../static/images/team.jpg"
          alt="Leftier"
          placeholder="blurred"
          layout="constrained"
          className={column}
        />
      </div>
    </Layout>
  )
}

export default about
